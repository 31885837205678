import { getReferrerAttribution } from "@outschool/attribution";
import { UserLocale } from "@outschool/gql-frontend-generated";
import { useLookupIP } from "@outschool/iplookup-client";
import { getLanguageFromLocale, useLocale } from "@outschool/localization";
import { captureError } from "@outschool/platform";
import { browseRootPath, searchPath, signUpPath } from "@outschool/routes";
import { guessBrowserTimeZone } from "@outschool/time";
import { useAnalytics, useTrackEvent } from "@outschool/ui-analytics";
import {
  ContinueWithGoogleOneTap,
  getPostLoginPath,
  useLoginWithGoogleOneTapMutationV2,
  useRefreshSessionToken,
  useTokenContext,
} from "@outschool/ui-auth";
import { useSignupToSearchExperiment } from "@outschool/ui-components-website";
import { useNavigation } from "@outschool/ui-utils";
import { useRouter } from "next/router";
import React from "react";

import { GOOGLE_CLIENT_ID } from "../Env";

function useLoginWithGoogleOneTap({
  onSuccess,
  onError,
}: {
  onSuccess: (isNewUser: boolean) => void;
  onError: (error: Error) => void;
}) {
  const loginWithGoogle = useLoginWithGoogleOneTapMutationV2();
  const analytics = useAnalytics();
  const { setTokens } = useTokenContext();
  const { ipInfoLoaded, isInGDPR } = useLookupIP();
  const locale = useLocale();
  const { userLocale } = getLanguageFromLocale(locale);
  const refreshSession = useRefreshSessionToken();

  return React.useCallback(
    async (token: string) => {
      const createOptions = {
        attribution: await analytics.attribution(),
        browserTimeZone: guessBrowserTimeZone(),
        isGiftCardSignup: false,
        isLeader: false,
        subscribe: ipInfoLoaded ? !isInGDPR : false,
        locale: userLocale === UserLocale.En ? undefined : userLocale,
      };

      const anonymousId = await analytics.anonymousId();
      const osRef = {
        ...getReferrerAttribution(),
        anonymousId,
      };

      try {
        const {
          data: { loginOrCreateAccountWithGoogleV2: response },
        } = await loginWithGoogle({
          variables: {
            createOptions,
            osRef,
            idToken: token,
          },
        });

        if (response === undefined) {
          onError(new Error("empty response"));
          return;
        }

        if (response.__typename === "LoginError") {
          onError(new Error("login error"));
        } else {
          const {
            isNewUser,
            authentication: { sessionToken, refreshToken },
          } = response;

          setTokens(sessionToken, refreshToken);
          await refreshSession();
          onSuccess(isNewUser);
        }
      } catch (e) {
        onError(e);
      }
    },
    [
      analytics,
      loginWithGoogle,
      onSuccess,
      onError,
      refreshSession,
      setTokens,
      isInGDPR,
      ipInfoLoaded,
      userLocale,
    ]
  );
}

const SignupWithGoogleOneTap = () => {
  const router = useRouter();
  const navigate = useNavigation();
  const trackEvent = useTrackEvent();

  // signup to search experiment
  const { isInTreatment, trigger } = useSignupToSearchExperiment();

  const hasTriggeredExperiment = React.useRef(false);
  const postLoginPath = getPostLoginPath() || "/";
  const postLoginPathIsRoot = postLoginPath === browseRootPath();

  const signupToSearchExperimentPath =
    postLoginPathIsRoot && isInTreatment ? searchPath() : postLoginPath;

  const triggerSignupToSearchExperiment = React.useCallback(() => {
    if (postLoginPathIsRoot && !hasTriggeredExperiment.current) {
      trigger();
      hasTriggeredExperiment.current = true;
    }
  }, [postLoginPathIsRoot, trigger]);

  // END signup to search experiment

  const loginWithGoogle = useLoginWithGoogleOneTap({
    onSuccess: isNewUser => {
      if (!isNewUser) {
        trackEvent("signin-by-google-onetap");
        router.reload();
      } else {
        // signup to search experiment
        triggerSignupToSearchExperiment();
        if (isInTreatment) {
          navigate(signupToSearchExperimentPath, { hardNav: true });
        } else {
          // END signup to search experiment
          router.reload();
        }
      }
    },
    onError: error => {
      console.log(error);
      captureError(error);
      navigate(signUpPath(), { hardNav: true });
    },
  });

  return (
    <ContinueWithGoogleOneTap
      clientId={GOOGLE_CLIENT_ID as string}
      loginWithGoogle={async credential => {
        loginWithGoogle(credential);
        trackEvent("one-tap-prompt-click");
      }}
      onLoad={() => {}}
      onPrompt={notification => {
        if (notification.getMomentType() === "display") {
          trackEvent("one-tap-prompt-view");
        } else if (notification.getMomentType() === "skipped") {
          trackEvent("one-tap-prompt-cancel");
        }
      }}
    />
  );
};

export default SignupWithGoogleOneTap;
